import { PEN_COLOR } from "./Globals";
import Whiteboard from "./Whiteboard";
var App = /** @class */ (function () {
    function App() {
    }
    App.prototype.init = function () {
        var _this = this;
        console.log("Initializing default configurations..");
        var whiteboard = new Whiteboard();
        this.whiteboard = whiteboard;
        whiteboard.clear_white_board();
        whiteboard.board.ontouchmove = function (e) { return whiteboard.draw(e); };
        whiteboard.board.ontouchstart = function (e) { return whiteboard.pen_down(e); };
        whiteboard.board.ontouchend = function (e) { return whiteboard.pen_up(); };
        whiteboard.board.onmousemove = function (e) { return whiteboard.draw(e); };
        whiteboard.board.onmousedown = function (e) { return whiteboard.pen_down(e); };
        whiteboard.board.onmouseup = function (e) { return whiteboard.pen_up(); };
        whiteboard.board.onclick = function (e) { return _this.click_handler(e); };
        whiteboard.board.ondblclick = function (e) { return _this.dblclick_handler(); };
        whiteboard.board.oncontextmenu = function (e) { return _this.contextmenu_handler(e); };
        document.getElementById("eraserButton").onclick = function () { return _this.toggle_eraser(); };
        document.getElementById("downloadWhiteboardButton").onclick = function () { return _this.download_whiteboard_to_png(); };
        document.getElementById("clearWhiteboardButton").onclick = function () {
            whiteboard.clear_white_board(false);
        };
        // CTRL + Mouse wheel
        whiteboard.board.addEventListener("wheel", function (event) {
            if (event.ctrlKey) {
                event.preventDefault();
                var is_wheel_up = event.deltaY < 0;
                var is_wheel_down = event.deltaY > 0;
                if (is_wheel_up) {
                    whiteboard.increase_pen_size();
                }
                else if (is_wheel_down) {
                    whiteboard.decrease_pen_size();
                }
                if (whiteboard.is_eraser_enabled()) {
                    var eraserCircle = document.getElementById("eraserCircle");
                    eraserCircle.style.height = "".concat(whiteboard.current_eraser_size * 2, "px");
                    eraserCircle.style.width = "".concat(whiteboard.current_eraser_size * 2, "px");
                }
                console.log(whiteboard.current_pen_size);
                _this.update_color_selctor_circle_size();
            }
        });
        // Eraser circle
        whiteboard.board.addEventListener("mousemove", function (event) {
            var eraserCircle = document.getElementById("eraserCircle");
            if (whiteboard.is_eraser_enabled()) {
                eraserCircle.style.display = "block";
                eraserCircle.style.left = "".concat(event.clientX - whiteboard.current_eraser_size, "px");
                eraserCircle.style.top = "".concat(event.clientY - whiteboard.current_eraser_size, "px");
                eraserCircle.style.height = "".concat(whiteboard.current_eraser_size * 2, "px");
                eraserCircle.style.width = "".concat(whiteboard.current_eraser_size * 2, "px");
            }
            else {
                eraserCircle.style.display = "none";
            }
        });
        document.addEventListener("keydown", function (event) {
            // CTRL + Z
            if (event.ctrlKey && event.key === "z") {
                whiteboard.undo_stroke();
            }
            // CTRL + Y
            if (event.ctrlKey && event.key === "y") {
                whiteboard.redo_stroke();
            }
            // CTRL + space
            if (event.ctrlKey && event.key === " ") {
                whiteboard.clear_white_board();
            }
        });
        var _loop_1 = function (key, color) {
            var btn = document.querySelector(".color-".concat(key.toLowerCase()));
            if (btn) {
                btn.addEventListener("click", function () { return _this.change_pen_color(color); });
            }
        };
        for (var _i = 0, _a = Object.entries(PEN_COLOR); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], color = _b[1];
            _loop_1(key, color);
        }
        document.getElementById("undo-stroke").addEventListener("click", function () { return whiteboard.undo_stroke(); });
        document.getElementById("redo-stroke").addEventListener("click", function () { return whiteboard.redo_stroke(); });
        document.getElementById('helpButton').addEventListener('click', function () {
            var helpDialog = document.getElementById('helpDialog');
            helpDialog.classList.toggle('open');
            document.getElementById('helpButton').classList.toggle('help-button-active');
        });
    };
    App.prototype.contextmenu_handler = function (e) {
        e.preventDefault();
        this.whiteboard.draw(e);
        console.log("Context Menu Handler");
    };
    App.prototype.dblclick_handler = function () {
        console.log("Double click handler!");
    };
    App.prototype.click_handler = function (event) {
        //console.log(event);
        if (event.ctrlKey) {
            this.whiteboard.change_marker_color();
            this.update_color_in_color_selector();
        }
        else {
            this.whiteboard.draw(event);
        }
    };
    App.prototype.toggle_eraser = function () {
        if (this.whiteboard.is_eraser_enabled()) {
            this.whiteboard.change_marker_color(Whiteboard.supported_pen_colors[this.whiteboard.current_pen_color]);
        }
        else {
            this.whiteboard.change_marker_color(PEN_COLOR.ERASE);
        }
    };
    App.prototype.change_pen_color = function (color) {
        this.whiteboard.change_marker_color(color);
        this.update_color_in_color_selector();
    };
    App.prototype.update_color_in_color_selector = function () {
        // Update the color in color selector.
        var selected = document.querySelector("div.color-picker  span.selected");
        selected.style.backgroundColor =
            Whiteboard.supported_pen_colors[this.whiteboard.current_pen_color];
    };
    App.prototype.update_color_selctor_circle_size = function () {
        if (!this.whiteboard.is_eraser_enabled()) {
            document.getElementById("currentColorCircle").style.width = "".concat(this.whiteboard.current_pen_size * 3, "px");
            document.getElementById("currentColorCircle").style.height = "".concat(this.whiteboard.current_pen_size * 3, "px");
        }
    };
    App.prototype.download_whiteboard_to_png = function () {
        var link = document.createElement("a");
        link.download = "whiteboard.png";
        link.href = document.getElementById("whiteboard").toDataURL();
        link.click();
    };
    return App;
}());
export default App;
