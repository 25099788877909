import { PEN_COLOR } from "./Globals";
import Stroke from "./Stroke";
var Whiteboard = /** @class */ (function () {
    function Whiteboard(parent, color, is_pen_down) {
        if (parent === void 0) { parent = document.body; }
        if (color === void 0) { color = PEN_COLOR.ERASE; }
        if (is_pen_down === void 0) { is_pen_down = false; }
        var _this = this;
        this.setCanvasSize = function () {
            _this.board.style.width = (_this.board.width = window.innerWidth).toString();
            _this.board.style.height = (_this.board.height = window.innerHeight).toString();
        };
        this.parent = parent;
        this.create_canvas();
        this.color = color;
        this.is_pen_down = is_pen_down;
        this.strokes_array = [];
        this.undo_strokes_array = [];
        this.current_pen_color = 0;
        this.current_pen_size = Stroke.DEFAULT_PEN_SIZE;
        this.current_eraser_size = Stroke.DEFAULT_ERASER_SIZE;
    }
    Whiteboard.prototype.create_canvas = function () {
        var _this = this;
        this.board = document.createElement("CANVAS");
        this.board.setAttribute("id", "whiteboard");
        this.ctx = this.board.getContext("2d");
        // Set the Pen color to currently selected color
        this.ctx.fillStyle =
            Whiteboard.supported_pen_colors[this.current_pen_color];
        this.setCanvasSize();
        window.onresize = function () {
            _this.setCanvasSize();
            _this.redraw_strokes();
        };
        this.board.style.cursor = Whiteboard.pen_cursor_black;
        this.parent.appendChild(this.board);
        this.setup_image_drag_and_drop();
    };
    ;
    Whiteboard.prototype.setup_image_drag_and_drop = function () {
        // Provide Image drop support
        this.board.ondragenter = function (evt) {
            evt.stopPropagation();
            evt.preventDefault();
            // console.log("DRAGENTER");
        };
        this.board.ondragleave = function (evt) {
            // evt.preventDefault();
            // console.log("DRAGLEAVE");
        };
        var ctx = this.ctx;
        var wb = this;
        this.board.ondrop = function (ev) {
            // console.log("DROP");
            ev.stopPropagation();
            ev.preventDefault();
            // TODO: Add the image to undo queue as well
            var new_img_stroke = new Stroke(null, null, null, null, null, ev.dataTransfer.files[0]);
            wb.strokes_array.push(new_img_stroke);
            Whiteboard.draw_image(ev.dataTransfer.files[0], ctx);
        };
        this.board.ondragover = function (ev) {
            console.log("DRAGOVER");
            ev.stopPropagation();
            ev.preventDefault();
            // ev.dataTransfer.dropEffect = "move";
        };
    };
    ;
    Whiteboard.prototype.draw_dot = function (x, y, pen_radius) {
        //console.log(`${x}, ${y}, ${pen_radius}`)
        if (pen_radius === void 0) { pen_radius = Stroke.DEFAULT_PEN_SIZE; }
        this.ctx.beginPath();
        this.ctx.arc(x, y, pen_radius, 0, 2 * Math.PI, true);
        this.ctx.fill();
    };
    Whiteboard.prototype.render_line = function (p1, p2, d) {
        //console.log(`p1.x: ${p1.x}, p1.y: ${p1.y}, p2.x: ${p2.x}, p2.y: ${p2.y}`);
        if (d === void 0) { d = Stroke.DEFAULT_PEN_SIZE; }
        var D = Math.hypot(p2.x - p1.x, p2.y - p1.y);
        var last_stroke = this.strokes_array[this.strokes_array.length - 1];
        for (var i = 0; i < D; i += Stroke.DEFAULT_PEN_SHARPNESS) {
            var x0 = void 0, y0 = void 0;
            var dt = i;
            var t = dt / D;
            if (p1.x == p2.x)
                x0 = p1.x;
            else
                x0 = (1 - t) * p1.x + t * p2.x;
            if (p1.y == p2.y)
                y0 = p1.y;
            else
                y0 = (1 - t) * p1.y + t * p2.y;
            this.draw_dot(x0, y0, last_stroke.pen_size);
            last_stroke.points.push({ x: x0, y: y0 });
        }
    };
    ;
    Whiteboard.prototype.draw = function (e) {
        // console.log(`which: ${e.which}, is_pen_down: ${whiteboard.is_pen_down}`);
        // console.log(`x: ${e.clientX}, y: ${e.clientY}`);
        var keyboardEvent = e;
        var touchEvent = e;
        var mouseEvent = e;
        if (e.type === "touchmove" && this.is_pen_down) {
            var last_stroke_points = this.strokes_array[this.strokes_array.length - 1].points;
            last_stroke_points.push({
                x: touchEvent.changedTouches[0].pageX,
                y: touchEvent.changedTouches[0].pageY,
            });
            if (last_stroke_points.length > 1) {
                this.render_line(last_stroke_points[last_stroke_points.length - 2], last_stroke_points[last_stroke_points.length - 1]);
            }
        }
        else if (e.type === "mousemove" &&
            this.is_pen_down &&
            e.which === 1 &&
            mouseEvent.clientX > 0 &&
            mouseEvent.clientX < window.innerWidth &&
            mouseEvent.clientY > 0 &&
            mouseEvent.clientY < window.innerHeight) {
            var last_stroke_point = this.strokes_array[this.strokes_array.length - 1].points;
            last_stroke_point.push({ x: mouseEvent.clientX, y: mouseEvent.clientY });
            if (last_stroke_point.length > 1) {
                this.render_line(last_stroke_point[last_stroke_point.length - 2], last_stroke_point[last_stroke_point.length - 1]);
            }
        }
        else if (e.type === "click") {
            var pen_size = this.is_eraser_enabled()
                ? this.current_eraser_size
                : this.current_pen_size;
            this.draw_dot(mouseEvent.clientX, mouseEvent.clientY, pen_size);
        }
        else if (e.type === "contextmenu") {
            var last_stroke = this.strokes_array[this.strokes_array.length - 1].points;
            last_stroke.push({ x: mouseEvent.clientX, y: mouseEvent.clientY });
            if (last_stroke.length > 2) {
                this.render_line(last_stroke[last_stroke.length - 2], last_stroke[last_stroke.length - 1]);
            }
        }
    };
    ;
    Whiteboard.prototype.clear_white_board = function (undo_mode) {
        if (undo_mode === void 0) { undo_mode = false; }
        // Draw the canvas background with default color
        this.ctx.fillStyle = PEN_COLOR.ERASE;
        this.ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
        // Change the Pen color back to selected color
        this.ctx.fillStyle =
            Whiteboard.supported_pen_colors[this.current_pen_color];
        if (!undo_mode) {
            this.strokes_array = [];
            this.undo_strokes_array = [];
        }
    };
    ;
    Whiteboard.prototype.change_marker_color = function (pen_color) {
        if (!pen_color) {
            this.current_pen_color =
                (this.current_pen_color + 1) % Whiteboard.supported_pen_colors.length;
            pen_color = Whiteboard.supported_pen_colors[this.current_pen_color];
            this.color = pen_color;
        }
        else {
            this.current_pen_color =
                Whiteboard.supported_pen_colors.indexOf(pen_color);
            this.color = pen_color;
        }
        this.ctx.fillStyle = pen_color;
        switch (pen_color) {
            case PEN_COLOR.BLACK:
                this.board.style.cursor = "url(".concat(Whiteboard.pen_cursor_black, ") 0 16, auto");
                break;
            case PEN_COLOR.RED:
                this.board.style.cursor = "url(".concat(Whiteboard.pen_cursor_red, ") 0 16, auto");
                break;
            case PEN_COLOR.GREEN:
                this.board.style.cursor = "url(".concat(Whiteboard.pen_cursor_green, ") 0 16, auto");
                break;
            case PEN_COLOR.BLUE:
                this.board.style.cursor = "url(".concat(Whiteboard.pen_cursor_blue, ") 0 16, auto");
                break;
            case PEN_COLOR.ERASE:
                this.board.style.cursor = "url(".concat(Whiteboard.pen_cursor_eraser, ") 15 28, auto");
                break;
            default:
                this.ctx.fillStyle = PEN_COLOR.BLACK;
                this.board.style.cursor = "url(".concat(Whiteboard.pen_cursor_black, ") 0 16, auto");
                break;
        }
    };
    Whiteboard.prototype.pen_up = function () {
        // console.log("PEN_UP");
        this.is_pen_down = false;
        // console.log(this.strokes_array);
    };
    ;
    Whiteboard.prototype.pen_down = function (event) {
        var keyboardEvent = event;
        var touchEvent = event;
        var mouseEvent = event;
        if (keyboardEvent.ctrlKey)
            return;
        // console.log("PEN_DOWN");
        // Create a new stroke
        this.is_pen_down = true;
        var new_stroke;
        var stroke_pen_size = this.is_eraser_enabled()
            ? this.current_eraser_size
            : this.current_pen_size;
        var stroke_pen_color = this.is_eraser_enabled()
            ? PEN_COLOR.ERASE
            : Whiteboard.supported_pen_colors[this.current_pen_color];
        if (event.type === "touchstart") {
            new_stroke = new Stroke([
                {
                    x: touchEvent.changedTouches[0].pageX,
                    y: touchEvent.changedTouches[0].pageY,
                },
            ], stroke_pen_color, stroke_pen_size);
        }
        else if (event.type === "mousedown") {
            new_stroke = new Stroke([{ x: mouseEvent.clientX, y: mouseEvent.clientY }], stroke_pen_color, stroke_pen_size);
        }
        this.strokes_array.push(new_stroke);
    };
    ;
    Whiteboard.prototype.undo_stroke = function () {
        console.log("UNDO LAST STROKE!");
        // console.log(this.strokes_array);
        var last_stroke = this.strokes_array.pop();
        if (last_stroke)
            this.undo_strokes_array.push(last_stroke);
        this.redraw_strokes();
    };
    ;
    Whiteboard.prototype.redo_stroke = function () {
        console.log("REDO LAST UNDO STROKE!");
        console.log(this.strokes_array);
        var last_undo_stroke = this.undo_strokes_array.pop();
        if (last_undo_stroke)
            this.strokes_array.push(last_undo_stroke);
        this.redraw_strokes();
    };
    ;
    Whiteboard.prototype.redraw_strokes = function () {
        this.clear_white_board(true);
        // console.log(`redraw_strkoes(): ${this}`);
        for (var i = 0; i < this.strokes_array.length; i++) {
            // draw image strokes
            if (this.strokes_array[i].image) {
                Whiteboard.draw_image(this.strokes_array[i].image, this.ctx);
                continue;
            }
            // draw normal strokes
            var points = this.strokes_array[i].points;
            this.ctx.fillStyle = this.strokes_array[i].color;
            // console.log(this.strokes_array[i].color);
            for (var j = 0; points.length > 0 && j < points.length; j++) {
                this.draw_dot(points[j].x, points[j].y, this.strokes_array[i].pen_size);
            }
        }
    };
    ;
    Whiteboard.prototype.is_eraser_enabled = function () {
        return this.ctx.fillStyle.toString().toLowerCase() === PEN_COLOR.ERASE.toLowerCase();
    };
    ;
    Whiteboard.prototype.increase_pen_size = function () {
        if (this.is_eraser_enabled()) {
            this.current_eraser_size += 1;
        }
        else {
            this.current_pen_size += 1;
        }
    };
    ;
    Whiteboard.prototype.decrease_pen_size = function () {
        if (this.is_eraser_enabled() && this.current_eraser_size > 1) {
            this.current_eraser_size -= 1;
        }
        else if (this.current_pen_size > 1) {
            this.current_pen_size -= 1;
        }
    };
    ;
    Whiteboard.draw_image = function (image_file, ctx) {
        var reader = new FileReader();
        reader.onload = function (event) {
            var img = new Image();
            img.onload = function () {
                var PADDING_LEFT = 100;
                var PADDING_RIGHT = 50;
                var PADDING_TOP = 50;
                var PADDING_BOTTOM = 50;
                var pos_x = PADDING_LEFT, pos_y = PADDING_TOP;
                var AR = img.width / img.height;
                var SCREEN_AR = window.innerWidth / window.innerHeight;
                var is_img_landscape = AR > 1;
                var is_screen_landscape = SCREEN_AR > 1;
                if (is_img_landscape) {
                    img.width = window.innerWidth - (PADDING_LEFT + PADDING_RIGHT);
                    img.height = img.width / AR;
                    pos_x = PADDING_LEFT;
                    pos_y =
                        PADDING_TOP +
                            (window.innerHeight - (PADDING_TOP + PADDING_BOTTOM + img.height)) /
                                2;
                }
                else {
                    img.height = window.innerHeight - (PADDING_TOP + PADDING_BOTTOM);
                    img.width = img.height * AR;
                    pos_y = PADDING_TOP;
                    pos_x =
                        PADDING_LEFT +
                            (window.innerWidth - (PADDING_LEFT + PADDING_RIGHT + img.width)) /
                                2;
                }
                // console.log(`IMG_W: ${img.width}, IMG_H: ${img.height}`);
                ctx.drawImage(img, pos_x, pos_y, img.width, img.height);
            };
            img.src = typeof event.target.result === 'string' ? event.target.result : Buffer.from(event.target.result).toString();
        };
        reader.readAsDataURL(image_file);
    };
    Whiteboard.default_pen_color = PEN_COLOR.BLACK;
    Whiteboard.supported_pen_colors = [
        PEN_COLOR.BLACK,
        PEN_COLOR.RED,
        PEN_COLOR.GREEN,
        PEN_COLOR.BLUE,
    ];
    // Designed on https://www.piskelapp.com/p/create/sprite by Neetish Raj
    Whiteboard.pen_cursor_black = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAl5JREFUWEftlM2K00AAgCeT0PSP0oMgiPoEvsDeq22akDSxrBfx0Is+g4snWZ/BPawH8bK0TTJpkjHSsz6AT6AgCB6k9I8204nOUI/dNm0WL80llA7zffNlZgTwnx8hK36tVusNh8N22vkyETAMAxNCGpIkDRFCD9NIHCxgGMbHOI7rDLparYAoip/CMOS/d3kOEmg2m30IoUUpBblcDhBCQJIkiBCSRFFk3qiApmmXEMIOgydJAgRBAHEce1EUGQysKMoFxvj5Nom9CrRarcvxeNwplUpgsVgA9hYEwe33+3zVuq5feJ63Fc7GphZoNBpXEMLTfD7P4RBClt7FGHO4ZVlvbNt+uW3l//5PJcC+uSRJ1nqzAZafUuqGYcjhiqJ0MMbvdoWnKmCa5oBSqs1mM1Aul/mGI4Q4GGOLTWSa5onjOF/SwHcW+Dt5BCF8NBqNAEvPCuRyOQchxOGGYTxDCL1PC99JoNlsfqWUPmC5RVEElUoFjMdjOwzDx+vsZxjj833gWwU0TfspiuJtPlAQ+HFbLpd9jDG/ctvt9qter/d6X/i1Arqu/6KU3mJg9r3ZG0Lo+L7Ps1uWdW7b9tkh8I0Cqqp+lmX5ZDqdgmKxCCaTCSgUCp7nefySUVX1bRAELw6FbxTQNO0HpfSOJElgPp+DarU66Ha7+nrDfUAIPc0Cfp3A9ziO78myzM75wPd9DldV9SoIgidZwTcKKIryrVQq3Z/NZigMwxYbWK/Xr6IoyhS+UUDX9W4cx3dFUfxNCJmws++67mmWK9/rKj4KHAscCxwLHAscC9xEgT+kLwQwby8GGQAAAABJRU5ErkJggg==";
    Whiteboard.pen_cursor_red = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAlJJREFUWEelV1mWxCAIxL7/mYd5CCirzJKPfp1EWYoqMAsWwEIABKC/gPIL++In6Woe54XFE7uXfP/PuotEc7hWi9ijvyIAv6RO9m5bCwER8AsAPozYagEK2RO4xlKN9g/CQU0UF8CHbwTcGYI+2m1lAUYO+PS2c3JMP4QG8UmuN7piZy+S/YFvEch0j6jsFbJ+GER1fDnxYGeOso4mmjiwkzu6+VwJ0Vp2TvjSy4dqTgBnzSSxBei8k3Ar5yHk0j4xRYMsZZ9Za3xzdpLBJp3EllB95TSQkNMQXh3CKdGu8w048bVRlQlB/1oSWto2wkFiOpeTPJHmd5npaXZu4Q/p31tft5qnvGY3GYsr/ZcOTjFQJ2olpzLjJTm9qWHczD1kxy7F4s1q9xioL5tM5JFxu8O6meRqznOL6/GYW9PLLEOGF792zVnKnu2GcE2S8XFedkcwB3ALpQHv5G1y0pkJ783FHuDSXV19lg1Hc7qqU17s63tVS7jL5Ff9WTiaXmmM0Rfd6WyTidPPt4dTpzG/LkEpr6n6PAljuSqND1xw3bZo0e2s2naPcQnplajiec93je1rxCjLnAZnGrfK6+LzLqtDQ7mzPJIk4YeqPo9WFqQjwLYG5kUvrleQs+VCBTWIMfLqkGHoktt+T9Rkeg67bL1nPIXvjLc5j8AkZfMRU5qd3qdNVL4XblNA0eBL8KkZcTesx/GvHPPiX21x5H7u1Jcdtuo8fj/0monzolBBdYr+SWd6Y9BVOgRgb8d+2n89jwcUj0MmtEvmjzKthlYhnW/eFREzmms7QAAAAABJRU5ErkJggg==";
    Whiteboard.pen_cursor_green = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAlBJREFUWEelV0lyBCEMg/z/zUMKb1jGBibpQ6p6GrzIkiC9/evprbXx7witQZxvg9r6Wcl8kWfFqSN22WAr3pP78L23MT5D01MR9P0UrrfWbYvteEQTAw+YAmPgkIjBcXMCWV520cygNoe0MofwAzPA6VptOq3hRjA/ej7dptHb6J/eZvswwdV94ITOhRHhnRtU9QhCPQj7DPRDQCiiW+ysn/lbmFUQxMZn+oGSc0D5O6dAP9G7Sy5MOyC7FXChoetcekLSUXLtdsER+nevSzJ39TLhPFF88kUHjuSrEARmZYSPewq5eIAX7GAyU8Qnwh2hXI0c9GqONoqyeeZ9qBCgL07B7TLnVWalD/j+VCQaA4fG1KKw6gAgfkX5pOTIE9Sylmso6xCdy8WZ54pBPoTZA2PDyBi9ZVjqcEo7gv3sVWI2uooIrM/CCDhgsCi2HzlQJK0sZthTQWSH863MnK2AuZThWbTtojRgDW83hcSIKIoVoGyWlll8JpB1EM0PfmJ5X7s1WQFBHMMOC/kAR3dCJqZndJoz/MtIruucaKjV6wasxQrb7bEawdHHUtHDaKqr4uaw+2n4ljlkUwIoptk9tQAtQeBQQiK9mCs7cNKIQTHJGneWoxOnFIidvF6urgh42qgRZB1ZjYCSf8lnIAVcWP2MKx4Ie1RNN+hKXt4Jy416GfmD3uGC4qCsjIh15K90y8RKlt7dYZcJh33ZmazJkXL/I0CpSOouXnslYSUhDWD1H5pIP+0yPDhFvJgoZNuWBygTLwkX2Ae3+d4uyx2/EYsLNOiPRfkAAAAASUVORK5CYII=";
    Whiteboard.pen_cursor_blue = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAk9JREFUWEedV1mWBCEI07n/mcd5yBYQl5r+qK7XIksg0e6ttdbp0Vob89lbl7cxVwYbDHkXW9zEy721wR7sY1vYZ1zlRdpGXxKa3pMTyXDayVJKRePRqtYiSWt5tU+trVEafZShxfkmpNc6GD+ym1Dws4gbIcKMAQNEefWxtGK08QvFd8qhx+hVAY760jM3v1TeaTKGx7JZsWpywcuMULowaGf7hIzAHqdOuqkz7TOxcU3jqeOPr6H3BQ5ceVj4Uao8B9cRfSobhmpWHpOC4My3VLnWqRMVmkxdUDRREVAPjE7DbIHjSUqA2pTJqgBKEeEMGFSCs1AtK42JSFH5HVzBRvPUshBglSCFz/iOLdRS14hXIm2kBvaV026wlz2/V46MCgOVMBitochY0XNYRD+vVANdlrxd3ubAKG+SoQTf9Fy196rSNy2qHfyDat5LI1UZO/LC4At5mMoQ/CbtAvtUXoAdOH06kRcgXYA5iBzLEns2aVaiC2zDwW1Q7BdTU3C21F+xvBwgLwQVi7dzZi7fp4AxAxE8Hzi5/NQeXObnPuvJO78eLR8otBZ8olVudXmfgCnteinDm9iuV/lauBnyYh72XHUEks15S75e+nnyVRbsQuKnQTy9bg4ZrccLYAHZjgXV3bim1eOw7cxiAlJuEqXQ0wWRCqIbbJDNlQUffLHbjxvoDil/Ri7qYsv3CCcEcysEgbvTsBHNP24tE3jz8WZVBdj+QRIO1noCJw9f9orL5TMDHoToFdXVFfzyDz1IM3CAWZfyd6EOe6xW/3/ydgw1zN04NQAAAABJRU5ErkJggg==";
    Whiteboard.pen_cursor_eraser = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAotJREFUWEeVV1mWwzAIM73/met5bGa3p/mZaWILIQROYD0vWGvtfhWsBRufXtbgzjsEY4c18iPeg7X2QOTQwx147TlowtYdPYOnOmmBoVXRBhX8lh/CPSQPiqhquif+rQToeQ3gxO2IYpQmmRy0GuI3BVoWsMmJXPorXuuzIeF3OTgUR46KRRL/aJCmYg/BGfRY3ccAgLU3MZov1wmXhQabk+CGnOqLZiBhtDBPLnFB6tNjG2NxhgEgCdgyHhxNwCd0XxKc6xAUeAhfqy3UJZz1QZxDUMJ7rn4ESv2mKbYXQp1peOawU9CVTf6VUlmiaRs/GBU6D9jnQkB50GbiNABEwriy+KEQMNCz1sFzoBPuVJgqbvjpkHI8iumDNEYwZe5EIjCbAK6FhcS9+Y5rKBEkOq1XZfcXF5Lf3eSxXVMzphOAAVwF/L6OAyfpjl8kMYbN4DyMdLlWNb43uAblhQKiPvfBmTqQEgdVSsEvJAEiwVWTHzUvJRAFvrTbH3UAH6dlVxhNbdPGscGEZh0SliKT+CIJiUnY6IlP8EToCpaDcuP797eoaEJPl58IiaZ/tRxOBJmIAZMgrbyFTm+QaEsScm9Rwlvx42eLhBrcnouRuqCp1GEtxwp1BXvCX9qiMqdro5ht+YDyvSEeyV3QVu10B3pC5P9gu9luF0r7Jg7fzpAxnQIRa6FfATScUH622YDRiXHOzfA2kaO8frNw8kWSs/Kmc+uaSdFId48cztHmLdjbapi5TUdW+VxKuSvxHNSq65lY68p37gOIRwVitfWzSiVNXqhNv9uWPrN/mDBn1L0JTcWLjMP7iWiUmnr6EE5A3bJigarjD204ZNRIrBMugMdU3fBS75ob/wD1aDs32JEreQAAAABJRU5ErkJggg==";
    return Whiteboard;
}());
export default Whiteboard;
