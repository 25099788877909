import { PEN_COLOR } from "./Globals";
var Point = /** @class */ (function () {
    function Point() {
    }
    return Point;
}());
export { Point };
var Stroke = /** @class */ (function () {
    function Stroke(points, color, pen_size, pen_sharpeness, isLine, image) {
        if (points === void 0) { points = []; }
        if (color === void 0) { color = PEN_COLOR.BLACK; }
        if (pen_size === void 0) { pen_size = 3; }
        if (pen_sharpeness === void 0) { pen_sharpeness = 2; }
        if (isLine === void 0) { isLine = false; }
        if (image === void 0) { image = null; }
        this.points = points;
        this.color = color;
        this.pen_size = pen_size;
        this.pen_sharpeness = pen_sharpeness;
        this.is_line = isLine;
        this.image = image;
    }
    Stroke.DEFAULT_PEN_SIZE = 3;
    Stroke.DEFAULT_ERASER_SIZE = 32;
    Stroke.DEFAULT_PEN_SHARPNESS = 2;
    return Stroke;
}());
export default Stroke;
